<template>
  <div
    id="app"
    :class="$root.multi ? 'multi' : 'single'"
    @drop.prevent="$root.drop"
  >
    <main>
      <List :key="$root.route" :id="$root.route" />
    </main>
    <aside>
      <h3>lists</h3>
      <a
        class="project"
        :class="{ active: $root.route === project.list }"
        v-for="project in $root.projects"
        :key="project.list"
        :href="project.list"
        @click.prevent="$root.routerGoto(project.list)"
      >
        <h2>/{{ project.list.split('/').slice(3).join('/') }}</h2>
        <p>{{ project.done }} / {{ project.all }} items</p>
      </a>
      <form @submit.prevent="$root.addProject($root.newProjectName)">
        <input
          class="inp inp-unfocus"
          type="text"
          v-model="$root.newProjectName"
          placeholder="Add list"
        />
        <p v-if="$root.newProjectName">
          <button type="submit" class="btn btn--sm btn--subtle">
            Add project
          </button>
        </p>
      </form>
    </aside>
    <p>
      <button class="btn btn-moon" type="button" @click="$root.toggleTheme">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M19 5a7.6 7.6 0 1 0 0 15 10 10 0 1 1 0-15z" />
        </svg>
      </button>
    </p>
  </div>
</template>

<script>
import List from './components/List.vue'

export default {
  name: 'app',
  components: {
    List,
  },
}
</script>

<style>
@import './assets/main.css';

/* Theme */
html {
  --bg: #000;
  --fg: #fff;
  --bg3: 0, 0, 0;
  --fg3: 255, 255, 255;
  --sheet: #272822;
  --sheet2: #373832;
}
/*
@media (prefers-color-scheme: light) {
  html {
    --bg: #fff;
    --fg: #000;
    --bg3: 255, 255, 255;
    --fg3: 0, 0, 0;
  }
}
*/

* {
  box-sizing: border-box;
}
html,
body {
  background: var(--bg) !important;
  color: var(--fg);
  margin: 0;
  font-family: Inter, Dosis, Cabin, segoe ui, Roboto, helvetica neue, system-ui,
    sans-serif;
  /*height: 100%;*/
  touch-action: manipulation;
  touch-action: pan-y;
}

/* Footer > sticky */

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Item */

.items {
  padding: 1rem 0;
}
.item {
  position: relative;
  transform-origin: 30px 50%;
}
.item.checked {
  color: rgba(var(--fg3), 0.3);
  text-decoration: line-through;
}
.item--dragging {
  background: rgba(var(--fg3), 0.2);
  /*transform: scale(.9);*/
  border-radius: 1.25em;
}
.item--focus {
  background: var(--sheet);
}

/* Item > Checkmark */

.check {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  padding: 0.25em;
  height: 2.5em;
  width: 2.5em;
  cursor: pointer;
}
.check::before {
  content: '';
  display: block;
  background: rgba(var(--fg3), 0.3);
  box-shadow: none;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  transform: scale(0.15);
  transition: transform 0.2s;
}
.item:hover .check::before {
  background: #000;
  background: var(--fg);
  transform: scale(0.2);
}
.item .check:hover::before {
  transform: scale(0.6);
}
.checked .check::before {
  transform: scale(0.4);
}
.checked .check::before {
  background: rgba(var(--bg3));
  box-shadow: inset 0 0 16px 1px rgba(var(--fg3), 0.5);
}
.checked:hover .check::before {
  background: rgba(var(--bg3));
  transform: scale(0.4);
}

/* Item > Text input */

.label {
  display: block;
  line-height: 1.25em;
}
.item .growing {
  padding: 0.5em 10px 0.5em 2.5em;
}

/* Item > info */

.item__info {
  pointer-events: none;
  position: absolute;
  bottom: -6px;
  left: 2.5em;
  opacity: 0.5;
}
.item__info > span {
  font-size: 12px;
  margin-right: 0.5em;
}
.meta {
  font-size: 14px;
  opacity: 0.6;
}

/* List transition */

.list-enter-active {
  transition: opacity 0.4s 0.2s, transform 0.4s;
}
.list-leave-active {
  transition: opacity 0.4s, transform 0.4s 0.2s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(-1.125em);
}
.list-enter-active ~ .item {
  transition: transform 0.4s;
}
.list-leave-active ~ .item {
  transition: transform 0.4s 0.2s;
}
.list-enter ~ .item,
.list-leave-to ~ .item {
  transform: translateY(-2.25em);
}
.list-move {
  transition: transform 0.2s;
}

/* Nav */

nav {
  display: flex;
  margin: 0 auto;
  max-width: 35rem;
  background: white;
}

/* Nav > buttons */

nav button {
  border: none;
  padding: 1rem;
  text-align: left;
  background: none;
  cursor: pointer;
  opacity: 0.5;
}
nav:hover button {
  background: #eee;
  opacity: 1;
}
nav button:hover {
  background: #ddd;
}
nav button:focus {
  background: #ccc;
}
button small {
}
button small + small::before {
  content: ' · ';
  margin: 0 3px;
}

/* Nav > settings button */

.btn--settings {
  flex-grow: 1;
}

.btn--right {
  text-align: right;
}

.form-group {
  display: block;
  margin-bottom: 1em;
}

.form-group h3 {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: normal;
  opacity: 0.5;
  letter-spacing: 0.5px;
}

.checkbox input {
  float: left;
  width: auto;
  margin-right: 0.5em;
}
</style>
