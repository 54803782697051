<template>
  <div>
    <div class="omnibar" :class="{ focus, noResults }">
      <label class="omnibar-text">
        <TextareaSubtle
          :placeholder="creating ? 'Add...' : 'Search...'"
          v-model="model"
          @focus="setFocus(true)"
          @blur="setFocus(false)"
          @enter.prevent="tabToLocation"
        />
      </label>
    </div>
    <div v-if="value === 'debug'">
      <button class="debug-toggle" @click="$parent.toggleDebug">
        debug is {{ $parent.show.debug ? 'on' : 'off' }}
      </button>
    </div>
    <form @submit.prevent="confirm" class="container" v-if="noResults">
      <label class="form-group">
        <h3>Location</h3>
        <input
          class="inp"
          ref="location"
          type="text"
          v-model="meta.location"
          placeholder="In welke doos?"
        />
      </label>
      <div class="form-group">
        <h3>Tags</h3>
        <MultiSelect v-model="meta.tags" :options="recentTags" />
      </div>
      <div class="form-group">
        <h3>Last used</h3>
        <MultiSelect
          single
          v-model="meta.lastUsed"
          :options="lastUsedOptions"
        />
      </div>
      <div class="form-group">
        <h3>More details...</h3>
        <MultiSelect single @input="addProp" :options="moreDetails" />
      </div>
      <pre style="font-size: 14px;" v-text="meta"></pre>
      <div class="sticky">
        <button class="btn-reset btn-cta" type="button" @click="ignore">
          Ignore
        </button>
        <button class="btn-reset btn-cta btn-primary" type="submit">
          Save
        </button>
      </div>
    </form>
    <div v-else-if="value">
      <div class="group" v-for="[group, items] in groups" :key="group">
        <h3>{{ group !== 'undefined' ? group : 'No location' }}</h3>
        <div class="item" v-for="(item, i) in items" :key="i">
          {{ item.content }}
          <div class="item__meta" v-if="item.json">
            <div class="meta" v-if="item.json.location">
              Location: {{ item.json.location }}
            </div>
            <div class="meta" v-for="(tag, t) in item.json.tags" :key="t">
              {{ tag }}
            </div>
            <a
              class="url"
              v-for="(url, u) in $parent.isURL(item.content)"
              :key="u"
              :href="url"
            >
              Visit {{ url }}
            </a>
          </div>
        </div>
        <div v-if="group !== 'undefined'">
          <button>Add item to {{ group }}...</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelect from './MultiSelect.vue'
import TextareaSubtle from './TextareaSubtle.vue'

export default {
  components: {
    MultiSelect,
    TextareaSubtle,
  },
  props: {
    id: String,
    value: String,
  },
  data() {
    return {
      focus: false,
      creating: false,
      tagOptions: ['klein', 'keuken', 'pot', 'was'],
      lastUsedOptions: recentMonths().concat(recentYears()).sort(byValue),
      moreDetails: [
        'value',
        'expiry date',
        'owner',
        'size',
        'picture',
        'count',
        'broken',
        'building',
      ],
      meta: emptyMeta(),
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    items() {
      // TODO: find a place to store this state
      return this.$parent.visibleCommits
    },
    recentTags() {
      // TODO: find a place to store this state
      return sortBy(this.items, 'updated_at')
        .reverse()
        .flatMap(a => (a.json && a.json.tags) || [])
    },
    queryFilter() {
      const query = this.value
      return c => c.content.includes(query)
    },
    groups() {
      const prop = 'location'
      const items = this.items.filter(this.queryFilter)
      return groupBy(items, prop)
    },
    noResults() {
      return (this.value && this.groups.length === 0) || this.creating
    },
  },
  methods: {
    setFocus(v) {
      this.focus = v
    },
    addProp(prop) {
      this.$set(this.meta, prop, null)
    },
    async tabToLocation() {
      if (!this.creating) {
        this.creating = true
        await this.$nextTick()
      }
      this.$refs.location.focus()
    },
    confirm() {
      this.$emit('create', {
        content: this.model,
        json: this.meta,
      })
      this.ignore()
    },
    ignore() {
      this.creating = false
      this.model = ''
      this.meta = emptyMeta()
    },
  },
}

function emptyMeta() {
  return {
    location: '',
    tags: [],
    lastUsed: '',
  }
}

function groupBy(array, key) {
  return Object.entries(
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key]
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
      return objectsByKeyValue
    }, {})
  )
}

function sortBy(arr, field) {
  return arr.slice().sort((a, b) => {
    return a[field] < b[field] ? -1 : a[field] === b[field] ? 0 : 1
  })
}

function recentMonths() {
  const { format } = new Intl.DateTimeFormat(navigator.languages, {
    month: 'long',
  })
  return [0, 1, 2].map(ago => {
    const d = new Date()
    d.setDate(2)
    d.setMonth(d.getMonth() - ago)
    return {
      text: format(d),
      value: d.toJSON().slice(0, 7),
    }
  })
}
function recentYears() {
  const d = new Date().getFullYear()
  return [d, d - 1, d - 2].map(toOption)
}
function toOption(text) {
  return text && typeof text.text === 'string'
    ? text
    : { text, value: String(text) }
}
function byValue(a, b) {
  return b.value.localeCompare(a.value)
}
</script>

<style scoped>
.container {
  padding: 0 1.5rem;
}
.omnibar {
  padding: 0 1em;
  background: rgba(var(--bg3), 1);
}
.omnibar-text {
  position: relative;
  display: block;
  border-radius: 2em;
  font-size: 14px;
  padding: 0.5em 1.5rem;
  line-height: 1.5em;
  background: linear-gradient(rgba(var(--fg3), 0.1), rgba(var(--fg3), 0.1)),
    linear-gradient(var(--bg), var(--bg));
}
@media (max-height: 500px) {
  .omnibar-text {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
}
/*@media (max-width: 500px) {
  .omnibar {
    z-index: 3;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .omnibar-text {
    top: -1em;
  }
}*/
@media (min-width: 768px) {
  .omnibar {
    margin-bottom: 1em;
  }
}
.omnibar.focus > .omnibar-text {
  background: linear-gradient(rgba(var(--fg3), 0.2), rgba(var(--fg3), 0.2)),
    linear-gradient(var(--bg), var(--bg));
}
.omnibar.noResults {
  position: static;
}
.omnibar.noResults > .omnibar-text {
  position: static;
  top: 0;
  padding: 0.5em 0.5rem;
  background: none;
  font-size: 2em;
}

.group > h3 {
  margin: 2em 1.5rem 4px;
  font-size: 12px;
  opacity: 0.5;
}
.item {
  padding: 10px 1.5rem;
  font-size: 14px;
}

.sticky {
  position: sticky;
  bottom: 0;
  text-align: right;
  pointer-events: none;
}
.btn-cta {
  padding: 1em;
  background: rgba(var(--fg3), 0.1);
  pointer-events: auto;
}
.btn-primary {
  background: green;
}
</style>
