<template>
  <div class="growing">
    <div class="growing-div" :data-value="(value || placeholder) + '.'"></div>
    <textarea
      class="growing-textarea"
      :value="value"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
      @keydown.enter="$emit('enter', $event)"
      @keydown.delete="$emit('remove', $event)"
      @keydown.tab="$emit('tab', $event)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: 'TextareaSubtle',
  props: ['value', 'placeholder'],
}
</script>

<style>
.growing {
  display: block;
  position: relative;
  line-height: inherit;
  flex-grow: 1;
  box-sizing: border-box;
  background: none;
  outline: none;
}
.growing-textarea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: inherit;
  padding: inherit;
  font: inherit;
  line-height: inherit;
  color: inherit;
  background: none;
  box-sizing: border-box;
  overflow: hidden;
  resize: none;
  outline: none;
}
.growing-div {
  display: block;
  flex-grow: 1;
  white-space: pre;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  pointer-events: none;
}
.growing-div::before {
  content: attr(data-value) ' ';
  visibility: hidden;
  opacity: 0;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
</style>
