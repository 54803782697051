/*eslint-disable no-console*/
import { uniq } from '@bothrs/util/uniq'

export const basicFeatures = {
  local: ['commits', 'history'],
  data() {
    const parts = location.pathname.split('/')
    const hash = unserialize(window.location.hash)
    return {
      commits: [],
      route: window.location.origin + window.location.pathname,

      // leftover
      id: window.location.href,
      caches: [],
      history: [],
      newProjectName: '',
      auth: hash.p || hash.password || parts[2] || '',
      authError: '',
      p: {
        URL: '',
        Title: '',
      },
    }
  },
  computed: {
    publicURL() {
      return window.location.href
    },
    page() {
      return null
      // return (
      //   (this.caches.find &&
      //     this.caches.find(
      //       p =>
      //         p._id === this.id ||
      //         titleCompare(p.Title) === titleCompare(this.id)
      //     )) || {
      //     Title: ucfirst(this.id || '?'),
      //     links: [],
      //     Links: [],
      //   }
      // )
    },
    sortedProjectURLs() {
      return this.projectURLs.slice().sort((a, b) => a.localeCompare(b))
    },
    projectURLs() {
      return (this.commits || [])
        .filter(
          c =>
            c.json.updated_by !== 'server' &&
            !c.json.deleted_at &&
            !c.json.checked_at
        )
        .map(c => c.list)
        .filter(uniq)
    },
    projects() {
      return this.sortedProjectURLs.map(list => {
        const items = this.commits.filter(c => c.list === list)
        const all = items.filter(c => !c.json.deleted_at)
        const done = all.filter(c => c.json.checked_at)
        return {
          list,
          items: items.length,
          all: all.length,
          done: done.length,
        }
      })
    },
    multi() {
      return this.projectURLs.length > 1
    },
  },
  methods: {
    smallImage,
    reauth() {
      this.authFeedback = true
      this.fetchPage(this.id, this.auth)
    },
    async fetchPage(_id, auth) {
      console.log(_id, auth)
      // if (!_id) {
      //   return
      // }
      // auth =
      //   auth ||
      //   (
      //     this.caches.find(
      //       p => p._id === _id || titleCompare(p.Title) === titleCompare(_id)
      //     ) || {}
      //   ).Password ||
      //   this.auth ||
      //   ''
      // const page = await getJSON(
      //   '/api/pages/' + _id + '?' + serialize({ include: 'links' }),
      //   { auth }
      // )
      // if (page._id && page.links) {
      //   // console.log('page fetched', page)
      //   this.authError = ''
      //   this.caches = [page].concat(this.caches.filter(p => p._id !== page._id))
      //   if (
      //     titleCompare(_id) !== titleCompare(page.Title) &&
      //     _id !== page._id
      //   ) {
      //     console.log('not', _id, page._id, page.Title)
      //     this.goto(page)
      //   }
      // } else if (!this.authFeedback) {
      //   // Ignore
      // } else if (page.error) {
      //   console.warn('err', page)
      //   this.authError = page.error
      // }
    },
    routerGoto(url) {
      this.route = url
      window.history.pushState(
        url,
        null,
        '/' + url.split('/').slice(3).join('/')
      )
      window.scrollTo(0, 0)
    },
    async goto(project) {
      console.log(project)
      // this.auth = ''
      // this.authError = ''
      // this.authFeedback = ''
      // this.id = project._id || project.Title
      // this.fetchPage(this.id)
      // window.history.pushState(
      //   project.Title,
      //   null,
      //   '/' + slugify(project.Title)
      // )
      // window.scrollTo(0, 0)
    },
    popstate(event) {
      this.id = window.location.pathname.split('/')[1] || 'bothrs'
      console.log(
        'page.popstate',
        document.location,
        JSON.stringify(event.state)
      )
    },
    share(value) {
      if (window.navigator.share) {
        window.navigator.share({
          title: this.page.Title,
          text: 'Project landing page',
          url: value,
        })
      } else {
        this.copy(value)
      }
    },
    copy(value) {
      const inp = document.querySelector('#copyelem')
      inp.value = value
      inp.select()
      document.execCommand('copy')
    },
    addProject() {
      this.routerGoto(window.location.origin + '/' + this.newProjectName)
      this.newProjectName = ''
    },
    tryAddProject() {
      this.tryAddProjectDebounced()
    },
    // async addLink() {
    //   const { URL, Title } = this.p
    //   this.page.links.push({ URL, Title })
    //   this.p = {
    //     URL: '',
    //     Title: '',
    //   }

    //   await postJSON(
    //     '/api/links',
    //     { URL, Title, Page: [this.page._id] },
    //     this.bearer
    //   )
    //   this.fetchPage(this.page._id)
    // },
  },
  mounted() {
    if (!this.id) {
      alert('issue!')
      return
    }
    document.title = this.id
    this.fetchPage(this.id)
    this.tryAddProjectDebounced = debounce(async () => {
      const page = await getJSON('/api/pages/' + this.newProjectName, {
        auth: 'insecure',
      })
      if (page._id) {
        this.caches = [page].concat(this.caches.filter(p => p._id !== page._id))
        await this.goto(page)
        this.newProjectName = ''
      }
    })
    window.addEventListener('popstate', this.popstate)
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.popstate)
  },
  watch: {
    page(v, o) {
      if (v.Color !== o.Color) {
        const color = v.Color || '#888'
        const meta = document.querySelector("meta[name='theme-color']")
        meta.content = color
        document.documentElement.style.setProperty('--brand', color)

        // window.getComputedStyle($0)
      }
      if (v.Title !== o.Title) {
        document.title = v.Title
      }
      const base = '/' + v._id + '/'
      if (v._id !== o._id) {
        document.querySelector("link[rel='icon']").href = base + 'favicon.png'
        document.querySelector("link[rel='apple-touch-icon']").href =
          base + 'favicon.png?size=152'
        document.querySelector("link[rel='manifest']").href =
          base + 'manifest.json'
      }
    },
    theme(t) {
      document.documentElement.className = t
    },
  },
}

export function serialize(obj) {
  const str = []
  for (const p in obj) {
    if (obj.hasOwnProperty(p) && obj[p]) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    }
  }
  return str.join('&')
}
export function unserialize(str) {
  const query = str[0] === '#' || str[0] === '?' ? str.slice(1) : str
  const result = {}
  query.split('&').forEach(part => {
    const item = part.split('=')
    result[decodeURIComponent(item[0])] = decodeURIComponent(item[1])
  })
  return result
}
export function ucfirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function fetchJSON(url, options) {
  if (options.json) {
    options.body = JSON.stringify(options.json)
  }
  return fetch(
    url,
    Object.assign(
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: 'Bearer ' + options.auth,
        },
      },
      options
    )
  ).then(r => r.json())
}

export function getJSON(url, options) {
  options = options || {}
  options.method = 'GET'
  return fetchJSON(url, options)
}

export function postJSON(url, json, options) {
  options = options || {}
  options.json = json
  options.method = 'POST'
  return fetchJSON(url, options)
}
export function titleCompare(str) {
  return (str || '').toLowerCase().replace(/\s/g, '_')
}
export function slugify(str) {
  return (str || '').replace(/\s/g, '_')
}

export function debounce(func, wait = 300) {
  let timeout
  return function () {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      func.apply(this)
    }, wait)
  }
}

export function hostname(url) {
  if (!url) {
    return ''
  }
  if (!url.startsWith('http')) {
    url += 'https://'
  }
  const u = new URL(url)
  return u.hostname
}

export function clearbit(url) {
  return 'https://logo.clearbit.com/' + hostname(url)
  //.split('.').slice(-2).join('.')
}

export function smallImage(img) {
  if (Array.isArray(img)) {
    return smallImage(img[0])
  }
  if (
    img &&
    img.thumbnails &&
    img.thumbnails.large &&
    img.thumbnails.large.url
  ) {
    return img.thumbnails.large.url
  }
  return img && img.url
}
