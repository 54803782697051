<template>
  <section
    class="focus-layer"
    :class="{ 'focus-layer--active': active }"
    v-if="focusCommit || focusFile"
  >
    <div class="focus-100"></div>
    <div
      class="sheet"
      @touchstart="setActive(true)"
      @touchend="setActive(false)"
      @mouseenter="setActive(true)"
      @mouseleave="setActive(false)"
    >
      <!-- Add metadata -->
      <div class="action-bar" v-if="focusCommit">
        <input
          type="text"
          placeholder="Last used"
          class="inp inp-subtle"
          name=""
        />
      </div>
      <!-- Manage metadata -->
      <pre
        class="action-bar"
        v-if="focusCommit"
        v-html="focusCommit.json"
      ></pre>
      <!-- Manage files -->
      <div class="action-bar" v-if="focusFile">
        File:
        <button type="button" @click="unlinkFile(focus)">Remove</button>
      </div>
      <!-- Add files -->
      <div class="action-bar">
        <label class="inp-file">
          <input
            type="file"
            accept="image/*"
            capture="environment"
            @change="$root.handleFileInput($event, focus)"
          />
          <span class="btn">Add image</span>
        </label>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: { focus: null },
  data() {
    return {
      active: false,
    }
  },
  computed: {
    focusCommit() {
      if (!this.focus) {
        return
      }
      if (this.focus.startsWith('file:')) {
        return
      }
      return this.focus
        ? this.$parent.filteredCommits.find(
            c => c.id === this.focus && c.content
          )
        : null
    },
    focusFile() {
      if (!this.focus) {
        return
      }
      return this.focus.startsWith('file:')
    },
  },
  methods: {
    setActive(a) {
      this.active = a
    },
  },
}
</script>

<style>
.focus-layer {
  z-index: 10;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1000px;
  overflow: auto;
  pointer-events: none;
  /*padding-top: calc(100% - 50px);*/
  /*padding-top: calc(50%);*/
  padding-top: 950px;
  padding-left: 4px;
  padding-right: 4px;
}
.focus-layer--active {
  pointer-events: auto;
  background: rgba(0, 0, 255, 0.1);
}
.sheet {
  z-index: 11;
  position: relative;
  /*border: 2px solid rgba(var(--fg3), 0.4);*/
  border-bottom: 0;
  border-radius: 20px 20px 0 0;
  background: var(--sheet2);
  box-shadow: 0 0 4px rgba(var(--fg3), 0.4);
  /*margin-top: calc(var(--vh100, 100vh) - 50px);*/
  pointer-events: auto;
}
.action-bar {
  z-index: 12;
  position: relative;
  padding: 1em 1.5rem 2em;
  /*outline: 1px solid red;*/
  pointer-events: auto;
}
.inp-file {
  display: inline-block;
}
.inp-file > input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  pointer-events: none;
}
</style>
