import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'

import themeMixin from './lib/themeMixin.js'
import uploadMixin from './lib/uploadMixin.js'
import { basicFeatures } from './lib/page.js'
import VueLocal from './lib/VueLocal.js'
VueLocal.install(Vue)

// Vue.config.devtools = false
Vue.config.productionTip = false

Vue.config.errorHandler = err => {
  if (err && err.message.includes("property 'order' of")) {
    window.localStorage.commits = '[]'
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }
}

new Vue({
  data() {
    return {
      io: window.io(),
    }
  },
  mixins: [themeMixin, uploadMixin, basicFeatures],
  render: h => h(App),
}).$mount('#app')

if (
  window.matchMedia('(display-mode: standalone)').matches ||
  window.navigator.standalone === true
) {
  window.resizeTo(450, window.outerHeight)
}
