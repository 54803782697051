<template>
  <div class="multi-select">
    <button
      class="btn-reset multi-select-option"
      :class="{ selected: selected.includes(option.value || option) }"
      @click="toggle(option)"
      v-for="option in options"
      :key="option.text || option"
      v-text="option.text || option"
      type="button"
    ></button>
    <button
      class="btn-reset multi-select-option selected"
      @click="toggle(option)"
      v-for="option in others"
      :key="option.text || option"
      v-text="option.text || option"
      type="button"
    ></button>
    <input
      class="btn-reset multi-select-option inp-plus"
      type="text"
      v-model="other"
      @blur="toggle(other)"
      @keydown.enter.stop.prevent="toggle(other)"
      placeholder="+"
    />
  </div>
</template>

<script>
export default {
  props: {
    compact: Boolean,
    options: null,
    single: Boolean,
    value: null,
  },
  data() {
    return {
      other: '',
    }
  },
  computed: {
    others() {
      return this.selected.filter(
        s => !this.options.map(o => (o && o.value) || o).includes(s.value || s)
      )
    },
    selected() {
      return Array.isArray(this.value)
        ? this.value
        : [this.value].filter(Boolean)
    },
  },
  methods: {
    toggle(option) {
      const value = option.value || option
      const off = this.selected.includes(value)
      this.other = ''
      if (!value) {
        return
      }
      if (this.single) {
        return this.$emit('input', off ? null : value)
      }
      this.$emit(
        'input',
        off
          ? this.selected.filter(s => value !== s.value && value !== s)
          : this.selected.concat(value)
      )
    },
  },
}
</script>

<style>
.btn-reset {
  margin: 0;
  padding: 0;
  border: none;
  color: inherit;
  background: none;
  font: inherit;
}
.multi-select {
  overflow: auto;
  margin: -0.5em;
  font-size: 12px;
}
.multi-select-option {
  float: left;
  margin: 0.5em;
  border-radius: 0.5em;
  padding: 0.8em 1em;
  box-shadow: 0 0 4px rgba(var(--fg3), 0.6);
  background: rgba(var(--fg3), 0.1);
  color: var(--fg3);
  opacity: 0.8;
  white-space: nowrap;
}
.multi-select-option:hover {
  background: rgba(var(--fg3), 0.2);
}
.multi-select-option:focus {
  outline: 1px dotted rgba(var(--fg3), 0.3);
}
.multi-select-option:focus {
  /*outline: none;*/
}
.multi-select-option.selected {
  opacity: 1;
  background: blue;
}

.inp-plus {
  min-width: 1em;
  width: 3em;
  box-sizing: border-box;
  text-align: center;
}
.inp-plus:focus {
  width: 10em;
  text-align: left;
}
</style>
