<template>
  <div class="items" :class="{ large }">
    <Omnibar v-model="query" @create="create" />
    <transition-group name="list" v-if="!query">
      <div
        class="item"
        :class="{
          checked: item.json.checked_at,
          'item--focus': item.id === focus,
          'item--dragging': dragging === item,
        }"
        v-for="(item, i) in visibleCommits"
        :key="item.id || item.created_at"
        @dragenter="dragenter(item, i)"
        @drop.prevent="drop($event, item)"
      >
        <div
          class="check"
          @click="toggle(i)"
          draggable="true"
          @dragstart="dragstart(item, i, $event)"
          @dragend="dragend(item, i)"
        ></div>
        <label class="label">
          <textarea-subtle
            :value="item.content"
            placeholder="---"
            @focus="focus = item.id"
            @input="setText(i, $event)"
            @enter="enter(i, $event)"
            @remove="remove(i, $event)"
            @tab="tab(i, $event)"
          ></textarea-subtle>
        </label>
        <div
          class="item__files"
          v-if="item.json.files && item.json.files.length"
        >
          <div
            class="item__file"
            v-for="(file, f) in item.json.files"
            :key="file.url || f"
          >
            <img
              class="file-preview"
              v-if="file.url"
              :src="cdnURL(file)"
              :style="{ width: (file.width / file.height) * 40 + 'px' }"
              @click="focus = 'file:' + item.id + ':' + file.id"
            />
            <div v-else class="file-preview"></div>
          </div>
        </div>
        <div class="item__meta" v-if="item.json">
          <div class="meta" v-if="item.json.location">
            Location: {{ item.json.location }}
          </div>
          <div class="meta" v-for="(tag, t) in item.json.tags" :key="t">
            {{ tag }}
          </div>
          <a
            class="url"
            v-for="(url, u) in isURL(item.content)"
            :href="url"
            :key="u"
          >
            Visit {{ url }}
          </a>
        </div>
        <div class="item__info" v-if="show.info">
          <span v-if="item.id">-i{{ item.id || '-' }}</span>
          <span v-if="item.json.order">+o{{ item.json.order || '-' }}</span>
          <span v-if="item.json.created_by"
            >c{{ item.json.created_by || '' }}</span
          >
          <span v-if="item.json.created_by"
            >c{{ item.json.created_at | ago }}</span
          >
          <span v-if="item.json.created_by && item.json.updated_by"
            >&middot;</span
          >
          <span v-if="item.json.updated_by"
            >u{{ item.json.updated_by || '' }}</span
          >
          <span v-if="item.updated_by">u{{ item.updated_at | ago }}</span>
        </div>
      </div>
    </transition-group>
    <ActionBar v-if="focus" :focus="focus" />
    <div class="add" @click="add" v-if="!query"></div>
    <div class="container" v-if="show.debug">
      <label class="form-group">
        <h3>Options</h3>
        <input type="checkbox" v-model="show.info" />
        Show info
      </label>
    </div>
    <div class="container" v-if="nickInput || show.debug">
      <label class="form-group">
        <h3>Nickname</h3>
        <input
          class="inp"
          type="text"
          v-model="nick"
          placeholder="Wat is je naam?"
        />
      </label>
    </div>
    <div v-else-if="!nickInput">nick: {{ nickname }}</div>
  </div>
</template>

<script>
/*eslint-disable no-console*/
import getUrls from 'get-urls'
import { str62 } from '@bothrs/util/random'

import ActionBar from './ActionBar.vue'
import Omnibar from './Omnibar.vue'
import TextareaSubtle from './TextareaSubtle.vue'

export default {
  components: {
    ActionBar,
    Omnibar,
    TextareaSubtle,
  },
  props: {
    id: String,
  },
  local: ['nick', 'show', 'sync'],
  data() {
    return {
      dragging: null,
      draggingFrom: -1,
      draggingTo: -1,
      state: '',
      focus: null,
      show: {
        info: false,
        debug: false,
      },
      stats: {
        deleted: 0,
        inserted: 0,
        pushed: 0,
        received: 0,
        updated: 0,
      },
      query: '',
      sync: false,
      nick: '',
      nickInput: !window.localStorage.nick,
      connections: [],
    }
  },
  computed: {
    commits() {
      return this.$root.commits
    },
    nickname() {
      return this.nick || 'x' + hashCode(window.navigator.userAgent)
    },
    large() {
      return this.visibleCommits.length < 7
    },
    filteredCommits() {
      return this.commits
        .slice()
        .sort((a, b) => order(a) - order(b))
        .filter(c => !c.json.deleted_at && c.list === this.id)
    },
    visibleCommits() {
      if (this.draggingFrom !== this.draggingTo && this.draggingTo !== -1) {
        const out = this.filteredCommits.slice()
        const moving = out.splice(this.draggingFrom, 1)
        out.splice(this.draggingTo, 0, moving[0])
        return out
      }
      return this.filteredCommits
    },
  },
  methods: {
    dragstart(item, i, evt) {
      evt.dataTransfer.setDragImage(new Image(), 0, 0)
      this.dragging = item
      this.draggingFrom = i
      console.log('from', i)
    },
    dragenter(item, i) {
      if (this.draggingFrom > -1 && this.draggingTo !== i) {
        console.log('to', i)
        this.draggingTo = i
      }
    },
    dragend() {
      if (this.draggingFrom !== this.draggingTo) {
        const { dragging } = this
        const shift = this.draggingTo > this.draggingFrom ? 0 : -1
        const above = this.filteredCommits[this.draggingTo + shift]
        const below = this.filteredCommits[this.draggingTo + shift + 1]
        this.$set(dragging, 'updated_at', Date.now())
        this.$set(dragging.json, 'updated_by', this.nickname)
        this.$set(
          dragging.json,
          'order',
          below ? Math.ceil((order(below) + order(above)) / 2) : Date.now()
        )
        console.log(
          'move',
          order(dragging),
          '=>',
          dragging.json.order,
          below && order(below),
          order(above)
        )
        this.send([dragging])
      } else {
        console.log('same dragend', this.draggingFrom)
      }

      this.draggingFrom = -1
      this.draggingTo = -1
      this.dragging = null
    },
    add() {
      this.commits.push(this.createCommit())
      this.$nextTick(() => {
        const next = document.querySelector('.item:last-child textarea')
        next && next.focus()
      })
    },
    create(item) {
      console.log('item', JSON.stringify(item))
      this.commits.push(this.createCommit(item))
      this.query = ''
    },
    toggle(i) {
      i = this.commits.indexOf(this.visibleCommits[i])
      this.$set(this.commits[i], 'updated_at', Date.now())
      this.$set(this.commits[i].json, 'updated_by', this.nickname)
      this.$set(
        this.commits[i].json,
        'checked_at',
        this.commits[i].json.checked_at ? null : Date.now()
      )
      this.send([this.commits[i]])
    },
    isEmptyCommit(i) {
      return (
        this.commits[i] &&
        // Has no content
        ((!this.commits[i].json.deleted_at && !this.commits[i].content) ||
          // Or is deleted and the one above it is has no content
          (this.commits[i].json.deleted_at && this.isEmptyCommit(i - 1)))
      )
    },
    setText(i, value) {
      i = this.commits.indexOf(this.visibleCommits[i])
      this.$set(this.commits[i], 'content', value)
      this.$set(this.commits[i], 'updated_at', Date.now())
      this.$set(this.commits[i].json, 'updated_by', this.nickname)
      this.send([this.commits[i]])
    },
    enter(i, evt) {
      if (evt.shiftKey || evt.ctrlKey || evt.altKey || evt.metaKey) {
        return // ignore Shift+Enter
      }
      evt.preventDefault()
      i = this.commits.indexOf(this.visibleCommits[i])

      // If next item is empty, tab to it
      // if (this.isEmptyCommit(i)) {
      //   console.log('enter: tab to it')
      //   this.$nextTick(() => {
      //     const next = evt.target.closest('.item').nextElementSibling
      //     focusAndScroll(next, 'textarea')
      //   })
      //   return
      // }

      const above = this.commits[i]
      const next = this.visibleCommits[
        this.visibleCommits.indexOf(this.commits[i]) + 1
      ]
      console.log('a', above, next)
      const o = next ? Math.ceil((order(above) + order(next)) / 2) : Date.now()

      // Insert empty item
      console.log('enter: inset empty item')
      const commit = this.createCommit({ json: { order: o } })
      this.commits.push(commit)
      this.send([commit])
      if (evt) {
        console.log('enter: focus next')
        this.$nextTick(() => {
          const next = evt.target.closest('.item').nextElementSibling
          focusAndScroll(next, 'textarea')
        })
      }
    },
    remove(i, evt) {
      const item = this.visibleCommits[i]
      if (!item.content) {
        console.log('remove:', item)
        this.$set(item, 'updated_at', Date.now())
        this.$set(item.json, 'deleted_at', Date.now())
        this.send([item])
        if (evt.keyCode == 8) {
          const previous = evt.target.closest('.item').previousElementSibling
          previous && previous.querySelector('textarea').focus()
        } else {
          const next = evt.target.closest('.item').nextElementSibling
          next && next.querySelector('textarea').focus()
        }
        evt.preventDefault()
      }
    },
    tab(i, evt) {
      // If it's the last item, create a new one
      if (evt && !evt.shiftKey && i === this.visibleCommits.length - 1) {
        evt.preventDefault()
        const commit = this.createCommit()
        this.commits.push(commit)
        this.send([commit])
        this.$nextTick(() => {
          const next = evt.target.closest('.item').nextElementSibling
          focusAndScroll(next, 'textarea')
        })
      }
    },
    // sortCommits() {
    //   this.commits = this.commits
    //     .slice()
    //     .sort((a, b) => a.updated_at < b.updated_at)
    // },
    optimize() {
      this.$root.commits = this.commits
        .filter(c => !c.json.deleted_at)
        .filter(uniqBy('id'))
    },

    connect() {
      this.state = 'connected'
      this.optimize()
      console.debug('initial sync', this.commits.length)
      this.send(this.commits)
    },
    disconnect() {
      this.state = 'disconnected'
      this.optimize()
      console.debug('initial sync', this.commits.length)
      this.send(this.commits)
    },

    send(updates) {
      const ignore = updates.filter(c => !c || !c.id || !c.updated_at)
      ignore.length && console.warn(ignore)
      updates = updates.filter(c => c && c.id && c.updated_at)

      // this.connections.forEach(conn => {
      //   this.sendTo(updates, conn, 0)
      // })

      console.log('outgoing updates', JSON.parse(JSON.stringify(updates)))
      this.$root.io.emit('updates', updates)
    },

    // sendTo(data, conn, tries) {
    //   tries++

    //   if (conn.open) {
    //     conn.send(data)
    //   } else if (tries < 10) {
    //     setTimeout(
    //       this.sendTo.bind(this, data, conn, tries),
    //       500 * tries * tries
    //     )
    //   } else {
    //     this.connections.splice(this.connections.indexOf(conn), 1)
    //   }
    // },

    receive(data) {
      if (!data.forEach) {
        return console.debug('Received unexpected data', data)
      } else {
        this.stats.received += data.length
      }

      data.forEach(commit => {
        const existingIndex = this.commits.findIndex(
          c =>
            c.id === commit.id || String(c.created_at).slice(-10) === commit.id
        )
        const existingCommit = this.commits[existingIndex]

        if (existingCommit && existingCommit.updated_at >= commit.updated_at) {
          return console.log('receive whatefs')
        }

        // Update commits to newest version
        else if (existingCommit) {
          this.$root.commits = this.commits
            .filter(
              c =>
                c.id !== commit.id &&
                String(c.created_at).slice(-10) !== commit.id
            )
            .concat(commit)
          this.stats.updated++
          console.log(
            'receive update',
            existingIndex,
            1,
            commit,
            JSON.parse(JSON.stringify(commit))
          )
        }

        // Add missing commit
        if (!existingCommit && !commit.json.deleted_at) {
          this.commits.push(commit)
          console.log('receive push', JSON.parse(JSON.stringify(commit)))
        }
      })
    },
    join() {
      this.id &&
        this.$root.io.emit('join', this.id, a => {
          console.log('joined', a)
        })
    },
    isURL(str) {
      if (!str) return []
      if (str.startsWith('http')) return [str]
      const urls = getUrls(str)
      return urls
    },
    toggleDebug() {
      this.show.debug = !this.show.debug
    },
    createCommit(data) {
      if (!data) {
        data = {}
      }
      if (!data.list) {
        data = { ...data, list: this.id }
      }
      return createCommit(this.nickname, data)
    },
    drop($event, item) {
      this.$root.drop($event).map(async prom => {
        const file = await prom
        if (!file) {
          return console.warn('dropped no file')
        }
        this.$set(item, 'updated_at', Date.now())
        if (!item.json.files) {
          this.$set(item.json, 'files', [file])
        } else {
          item.json.files.push(file)
        }
        this.send([item])
      })
    },
    unlinkFile(id) {
      const [, commitId, file] = id.split(':')
      const commit = this.commits.find(c => c.id === commitId)
      if (commit.json.files) {
        commit.json.files = commit.json.files.filter(f => f.id !== file)
        this.$set(commit, 'updated_at', Date.now())
        this.send([commit])
      }
    },
    cdnURL(file) {
      const { origin } = window.location
      return file.size > 5e5 && !origin.endswith('localhost')
        ? 'https://images.weserv.nl/?w=500&url=' +
            encodeURIComponent(origin + file.url)
        : file.url
    },
  },
  mounted() {
    this.$root.io.on('disconnect', a => {
      console.log('disconnect fired!', a)
    })
    this.$root.io.on('updates', this.receive)
    this.$root.io.on('rejoin', this.join)
    this.join()

    this.connect()
    this.$root.io.on('reconnect', () => this.connect())
  },
  destroyed() {
    this.$root.io.emit('leave', this.id, console.log)
    this.$root.io.off('updates', this.receive)
    this.$root.io.off('rejoin', this.join)
  },
  filters: {
    ago: timeago,
  },
}

function createCommit(nick, data) {
  const json = data.json || {}
  delete data.json
  return {
    id: str62(10),
    list: 'lstdefault',
    updated_at: Date.now(),
    content: '',
    json: {
      order: Date.now(),
      created_at: Date.now(),
      created_by: nick,
      deleted_at: null,
      updated_by: nick,
      ...json,
    },
    ...data,
  }
}

function timeago(d) {
  if (!d) {
    return 'nooit'
  }
  if (typeof d === 'string') {
    d = d.replace(' ', 'T')
  }
  d = new Date(d)
  if (!d) {
    console.error('Report invalid date')
    return 'invalid'
  }
  const diff = new Date().valueOf() - d.valueOf()
  if (diff > 1000 * 60 * 60 * 24) {
    const MONTHS = 'jan,feb,maart,apr,mei,juni,juli,aug,sept,okt,nov,dec'.split(
      ','
    )
    return (
      d.getDate() +
      ' ' +
      MONTHS[d.getMonth()] +
      ' ' +
      pad(d.getHours()) +
      ':' +
      pad(d.getSeconds())
    )
  }
  if (diff > 1000 * 60 * 60) {
    return Math.round(diff / 36e5) + ' uur ago'
  }
  if (diff > 1000 * 60) {
    return Math.round(diff / 6e4) + ' min. ago'
  }
  return Math.round(diff / 1000) + ' s. ago'
}

function pad(t) {
  return t < 10 ? '0' + t : t
}

function uniqBy(prop) {
  return typeof prop === 'function'
    ? (v, i, a) => a.findIndex(v2 => prop(v) === prop(v2)) === i
    : (v, i, a) => a.findIndex(v2 => v[prop] === v2[prop]) === i
}

function focusAndScroll(elem, selector) {
  if (elem) {
    elem.querySelector(selector).focus()
    elem.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })
  }
}

function hashCode(str) {
  var hash = 0,
    i = 0,
    len = str.length
  while (i < len) {
    hash = ((hash << 5) - hash + str.charCodeAt(i++)) << 0
  }
  return String(Math.abs(hash) % 10000)
}

function order(obj) {
  return obj ? obj.json.order || obj.json.created_at : 0
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  padding: 0 1.5rem;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.items.large .item {
  font-size: 24px;
}
.url {
  display: block;
  font-size: 14px;
  padding: 0.5em 0;
}
.url:hover {
  text-decoration: none;
}
.add {
  height: 5em;
}
.item__meta {
  padding: 0 0 0 2.5em;
}
.item__files {
  display: flex;
  padding: 0 0 0.5em 2em;
}
.item__file {
  float: left;
  padding: 0 0.5em;
}
.item__files img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
</style>
