/*eslint-disable no-console*/
import { str62 } from '@bothrs/util/random'
import { putJSON } from '@bothrs/util/fetch'

export default {
  // data() {
  //   return {
  //     upload: {
  //       dragging: false,
  //       files: [],
  //     },
  //   }
  // },
  methods: {
    // dragend(evt) {
    //   console.log('dragend', evt)
    // },
    // dragenter(evt) {
    //   console.log('dragenter', evt)
    // },
    // dragover(evt) {
    //   // console.log('dragover', evt)
    // },
    // dragend(evt) {
    //   console.log('dragend', evt)
    //   evt.preventDefault()
    // },
    drop(evt) {
      evt.preventDefault()
      if (!evt.dataTransfer || !evt.dataTransfer.files) {
        return []
      }
      const out = []
      evt.dataTransfer.files.forEach(file => out.push(this.process(file)))
      return out
    },
    handleFileInput(evt, id) {
      console.log('handg', evt.target.files, evt, id)
      this.processFiles(evt.target.files).map(async prom => {
        console.log('dd', evt.target.files, evt, id)
        const file = await prom
        console.log('ee', evt.target.files, evt, id)
        if (!file) {
          return console.warn('selected no file')
        }
        const item = this.commits.find(c => c.id === id)
        if (!item) {
          return console.warn('item missing')
        }
        this.$set(item, 'updated_at', Date.now())
        if (!item.json.files) {
          this.$set(item.json, 'files', [file])
        } else {
          item.json.files.push(file)
        }
        this.$root.io.emit('updates', [item])

        // Wait for upload
        const saved = await file.upload
        Object.assign(file, saved)
        this.$set(item, 'updated_at', Date.now())
        this.$root.io.emit('updates', [item])
      })
    },
    processFiles(files) {
      const out = []
      files.forEach(file => out.push(this.process(file)))
      return out
    },
    process(fileObject) {
      return new Promise(resolve => {
        // Clone
        const { size, type, name, lastModified } = fileObject
        const file = { id: str62(10), size, type, name, lastModified }
        // console.log('file', file)

        if (file.type.startsWith('image')) {
          const reader = new FileReader()
          reader.onerror = resolve
          reader.onload = () => {
            const img = document.createElement('img')
            img.onerror = resolve
            img.onload = async () => {
              file.width = img.width
              file.height = img.height
              file.url = ''
              file.upload = putJSON('/api/uploads', {
                ...file,
                dataURL: reader.result,
              })
              // console.log('resolve', file)
              resolve(file)
            }
            img.src = reader.result
            // console.log('data uri', reader.result.length)
          }
          reader.readAsDataURL(fileObject)
        } else {
          resolve()
        }
      })
    },
  },
  // mounted() {
  //   this.$el.addEventListener('dragend', evt => this.dragend(evt), false)
  //   this.$el.addEventListener('dragenter', evt => this.dragenter(evt), false)
  //   this.$el.addEventListener('dragover', evt => this.dragover(evt), false)
  //   this.$el.addEventListener('dragend', evt => this.dragend(evt), false)
  //   window.document.body.addEventListener('drop', evt => this.drop(evt), false)
  // },
}

// export function handleFile (file) {
//   return new Promise(resolve => {
//     const imageType = /image.*/
//     if (!file.type.match(imageType)) {
//       return resolve()
//     }
//     const img = document.createElement('img')
//     img.classList.add('obj')
//     img.file = file
//     const reader = new FileReader()
//     reader.onload = (function (aImg) {
//       return function (e) {
//         const { result } = e.target
//         if (!result) {
//           return resolve()
//         }
//         if (result.slice(0, 26) === 'data:image/svg+xml;base64,') {
//           return resolve(atob(result.slice(26)))
//         }
//         console.log('onload reader', result)
//         aImg.onload = function () {
//           // console.log('onload reader img', this, aImg)
//           // ctx.drawImage(aImg, 0, 0)
//         }
//         // result is a dataURL for the image
//         aImg.src = result
//       }
//     })(img)
//     reader.readAsDataURL(file)
//   })
// }

// // function process_data(d) {
// //    // Process the data ...
// // }

// // dragTarget.addEventListener("dragend", function(ev) {
// //    // Call the drag and drop data processor
// //    if (ev.dataTransfer != null) process_data(ev.dataTransfer);
// //  }, false);
