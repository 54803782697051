export default {
  local: ['theme'],
  data() {
    return {
      theme: 'dark',
    }
  },
  methods: {
    toggleTheme() {
      this.theme = this.theme !== 'dark' ? 'dark' : 'light'
    },
  },
}
